import React,{useState ,useEffect, useRef } from 'react'
import DropDown from './DropDown'
import {BsChevronDown} from 'react-icons/bs'
import { Link } from 'react-router-dom'
export default function MenuItem({items}) {
    const [active, setActive] = useState("Process");

    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
         if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
         }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
         // Cleanup the event listener
         document.removeEventListener("mousedown", handler);
         document.removeEventListener("touchstart", handler);
        };
       }, [dropdown]);

       const closeDropdown = () => {
        dropdown && setDropdown(false);
      };
  return (
    <div>
       <li className='relative list-none'  ref={ref} onClick={closeDropdown}>
        {items.submenu ? (
                 <>
                 <Link to={items.url} className='flex gap-x-2 items-center'>
                   {items.title} 
                   <BsChevronDown   onClick={() => setDropdown((prev) => !prev)}/>
                 </Link>
                 <DropDown submenus={items.submenu} dropdown={dropdown} setdropdown={setDropdown}/>
               </>
        ):(
             <Link to={items.url} /* className={`${
                active === items.title ? "text-black" : "text-dimWhite"
              }`} */>{items.title}</Link>
        )
    }
        </li> 
    </div>
  )
}
