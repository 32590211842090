import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'
export default function ArticlePub() {
  return (
    <div className='flex flex-col w-full h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Article Publication</h1>
                </div>
            </div>

            <section className='w-full'>
                <div className='md:w-[80%] w-full md:mx-auto  py-[1rem] md:py-[1rem] text-justify bg-white'>
                    <h1 className='font-Roboto md:text-[25px] px-[1rem]  md:px-0'>Literature Writing: The Foundation of Academic Inquiry</h1>
                    <p className='leading-9 font-popins md:px-3 px-[1rem]  text-justify text-[16px]'>
                    The journey of an article doesn't end at the writing stage; it truly begins when it 
                    reaches its intended audience. Our Article Publication Services are designed to navigate
                     the complex waters of academic and mainstream publishing, ensuring your research,
                      insights, and perspectives gain the visibility and recognition they deserve.


                    </p>

                    <div className='py-[2rem] text-justify flex md:flex-row flex-col'>
                        <div className='md:w-[50%]'>
                            <h1 className='font-Roboto md:text-2xl px-2 md:px-0'>The Publication Process Demystified</h1>
                        </div>
                        <div className='md:w-[60%] flex flex-col gap-y-4 text-[16px] divide-y-2'>
                            <div className='px-3 flex md:flex-row flex-col items-start md:items-center  md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Targeted Submission</span>
                                    <p>
                                    Identifying the right journals or platforms that align with the article's subject matter

                                    </p>
                                </div>
                            </div >

                            <div className='px-3 mt-2 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Formatting and Compliance</span>
                                    <p>
                                    Ensuring your article adheres to specific guidelines set by publishers.

                                    </p>
                                </div>

                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Review Coordination</span>
                                    <p>
                                    Liaising with reviewers and editors for feedback and facilitating the necessary revisions.

                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex  md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Copyright Consultation</span>
                                    <p>
                                    Guiding on rights, permissions, and ensuring intellectual property protection

                                    </p>
                                </div>
                            </div>

                           
                        </div>
                    </div>

                    <div className='py-[2rem] px-[1rem] md:px-0 text-justify'>
                        <h1 className='font-Roboto md:text-2xl'>MREC's Commitment to Seamless Publication</h1>
                        
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 pt-10'>
                            <div className='flex flex-col gap-y-4 text-[16px] text-justify'>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#7e6ee6]'>
                                    <span className='text-white text-2xl'>Expert Guidance</span>
                                    <p className='text-white'>  Leverage the experience of our publication experts, well-versed with both open-access and traditional publishing avenues.
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#7e6ee6]'>
                                    <span className='text-white text-2xl'>Tailored Strategies</span>
                                    <p className='text-white'>
                                    Each article is unique, and so should be its publication strategy. We design a custom approach for every submission
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col md:items-end gap-y-4 text-[16px] text-justify'>
                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#7e6ee6]'>
                                    <span className='text-white text-2xl'>Efficient Communication</span>
                                    <p className='text-white'>
                                    Acting as the bridge between authors and publishers, we ensure transparent, timely, and effective communication

                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#7e6ee6]'>
                                    <span className='text-white text-2xl'>Post-Publication Support:</span>
                                    <p className='text-white'>
                                    From promoting your article to tracking its impact, our support continues even after publication

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>                   

                   
                </div>
            </section>
        </div>
  )
}
