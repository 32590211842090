import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'

export default function Thesiswrit() {
  return (
    <div className='flex flex-col w-full h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Thesis Writing</h1>
                </div>
            </div>

            <section className='w-full'>
                <div className='md:w-[80%] w-full md:mx-auto py-[1rem] md:py-[1rem] text-justify bg-white'>                  
                    <div className='md:py-[2rem] text-justify flex md:flex-row flex-col'>
                        <div className='md:w-[50%]  flex  items-center'>
                            <h1 className='font-Roboto md:text-2xl px-[1rem] md:px-0'>The Pillars of a Stellar Thesis</h1>
                        </div>
                        <div className='md:w-[60%] flex flex-col gap-y-4 text-[16px] divide-y-2'>
                            <div className='px-3 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Focused Research Question</span>
                                    <p>
                                    Pinpointing a specific, significant, and researchable query that serves as the foundation of your thesis.
                                    </p>
                                </div>
                            </div >

                            <div className='px-3 mt-2 flex  md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Comprehensive Literature Review</span>
                                    <p>
                                    Painting a full picture of existing knowledge and identifying the gaps your research aims to fill
                                    </p>
                                </div>

                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Robust Methodology</span>
                                    <p>
                                    Designing and detailing the research methods, ensuring their validity and reliability.
                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span  className='text-xl'>Analytical Depth</span>
                                    <p>
                                    Diving deep into data, interpreting results, and drawing meaningful conclusions.
                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Clear Structure and Flow</span>
                                    <p>
                                    Organizing content cohesively, ensuring a logical flow of ideas and arguments.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=' px-[1rem] md:px-0 py-[2rem] text-justify'>
                        <h1 className='font-Roboto md:text-2xl'>MREC's Support in Thesis Crafting</h1>
                       
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 mt-3'>
                            <div className='flex flex-col gap-y-4 text-[16px] text-justify'>

                                <div className='md:w-[80%] flex flex-col  justify-center gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Expert Mentorship</span>
                                    <p className='text-white'>  Collaborate with seasoned academics, experienced in guiding budding scholars through the thesis journey.
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col justify-center gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96] flex-1'>
                                    <span className='text-white text-2xl'>Research Assistance</span>
                                    <p className='text-white'>
                                    From data collection to analysis, benefit from our comprehensive research support services.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col items-end gap-y-4 text-[16px] text-justify'>
                                <div className='md:w-[80%] flex flex-col justify-center gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96] '>
                                    <span className='text-white text-2xl'>Writing Workshops</span>
                                    <p className='text-white'>
                                    Engage in sessions tailored to hone your academic writing skills, with a focus on thesis-specific challenges
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col justify-center gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Review and Feedback</span>
                                    <p className='text-white'>
                                    Our panel offers constructive critiques, ensuring your thesis stands up to rigorous academic scrutiny.

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>                   

                    <div className='w-full md:py-[2rem] px-3 md:px-0'>
                           <h1 className='font-Roboto text-2xl'>Commitment to Authenticity</h1>
                           <p className=' mt-2 text-[16px] leading-8 text-justify'>
                           Your voice, your research, and your insights deserve to shine authentically. With MREC's stringent quality checks,
                            we ensure your thesis maintains below 10% plagiarism, verified using top-tier tools, preserving the integrity of your work.

                              A thesis is both a beginning and an end – the conclusion of one phase and the commencement of the next in your academic journey.
                               With MREC by your side, you're equipped to craft a thesis that resonates, informs, and makes a mark.
 
                           </p>
                    </div>
                </div>
            </section>
        </div>
  )
}
