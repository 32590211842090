import React from 'react'
import { Link,useNavigate } from 'react-router-dom'
import {HiOutlineArrowNarrowRight } from "react-icons/hi";
//import lifecycle from '../../Component/LiteratureReviewProcess.png'
const aca=[{
    key:"Purpose: To uncover new knowledge and insights for the broader scientific community",
    
    
},{
    key:"Focus: Theoretical frameworks, comprehensive studies, and long-term implications",
    
},{
    key:"Outcome: Typically results in scholarly publications, conference papers, and contributions to academic discourse"
    
}
]

const prof=[
    {
        key:"Purpose: To address specific challenges or needs of an organization or its clientele"
        
    },
    {
        key:"Focus: Practical solutions, immediate applications, and tangible benefits for business or industry."
    },
    {
        key:"Outcome: Often leads to business strategies, product innovations, and actionable recommendations"
        
    }
]
export default function Academic() {
    const navigate=useNavigate()
    return (
        <div className='w-full flex  flex-col h-full  bg-white'>

            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto  md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-Roboto'>Academic Research</h1>
                </div>
            </div>

            <section className='w-full bg-white md:py-3 py-3 px-[1rem]'>
               
                <div className='md:w-[80%] w-full md:mx-auto flex items-center py-5 md:flex-row flex-col gap-x-9'>                 
                <p className='font-Roboto text-[24px]'>Understanding Research Academic vs. Professional</p>
                    <div className='md:w-[50%] w-full  font-popins   flex flex-col gap-y-4 text-justify  shadow-md p-6'>
                         <h1 className='font-bold font-raleway text-[20px]'>Academic Research</h1> 
                         {aca.map((a,index)=>(
                         <ul  key={index}>
                            <li className='text-[16px] leading-7'>{a.key}</li>
                         </ul>  
                           
                            ))}
                    </div>
                    <div className='md:w-[50%]    w-full  flex  flex-col gap-y-4  p-6 shadow-md  text-justify'>
                          <h1 className='font-bold font-raleway  text-[20px]'>Professional Research</h1>
                          {prof.map((p,index)=>(
                         <ul   key={index}>
                            <li className='text-[16px] leading-7'>{p.key}</li>
                         </ul>  
                           
                            ))}
                    </div>
                </div>
            </section>
          
             <section className='w-full   px-[1rem]  md:py-[2rem] md:h-[300px]'>
                    <div className='md:w-[80%]  md:mx-auto flex md:h-full flex-col justify-center'>
                           <h1 className='font-bold font-Roboto text-center text-xl '>MREC's Commitment</h1>
                           <div className='container md:mx-auto'>
                           <p className='mt-2 leading-8 font-Roboto text-justify'>
                           While both forms of research have their merits and contribute to societal progression,
                            at MREC, our heart lies in the world of academic research. We are dedicated to aiding scholars in their 
                            quest for knowledge, understanding, and innovation. Our services are tailored to provide comprehensive 
                            support to academic researchers, ensuring their work achieves the recognition and impact it deserves.

                           </p>
                           </div>
                    </div>
             </section>

            <section className='w-full md:py-20  py-5 '>
            <h1 className='text-center'>Service</h1>
                <div className='md:w-[80%] w-full md:mx-auto grid md:gap-x-9 md:gap-y-9 gap-y-4 md:grid-cols-4 justify-center'>
                        
                    <div onClick={()=>navigate('/service/academic/problem')}
                     className=' md:w-[300px] h-[150px]  flex flex-col justify-center  border-2 border-[#ddd] border-solid hover:bg-[#2d3092] transition hover:duration-300 ease-in-out hover:text-white cursor-pointer leading-7 px-[20px]  rounded-sm '>
                        <h1 className='font-space text-[20px] font-semibold leading-9'>Probelm Identification</h1>
                         <Link to='/service/academic/problem' className='text-[25px] text-blue-500 hover:text-white'> <HiOutlineArrowNarrowRight className='animate-bounce '/></Link> 
                    </div>
                    <div 
                    className='  w-[300px] h-[150px] bg-white flex flex-col justify-center  border-2 border-[#ddd] border-solid hover:bg-[#2d3092] hover:text-white cursor-pointer  px-[20px]  rounded-sm'>
                        <h1 className='font-space text-[20px] font-semibold leading-9'>Literature Writing</h1>
                         <Link to='/service/academic/literaturewritting' className='text-[15px]'>Read more</Link> 
                    </div>
                    <div className=' w-[300px] h-[150px] bg-white flex  flex-col justify-center  border-2 border-[#ddd] border-solid hover:bg-[#2d3092] hover:text-white cursor-pointer px-[20px]'>
                        <h1 className='font-space text-[20px] font-semibold leading-9'>Algorithm Development</h1>
                         <Link to='/service/academic/algorithmdevelopment' className='text-[15px]'>Read more</Link> 
                    </div>
                    <div className='w-[300px] h-[150px] flex  flex-col justify-center  border-2 border-[#ddd] border-solid hover:bg-[#2d3092] hover:text-white cursor-pointer px-[20px]'>
                        <h1 className='font-space text-[20px] font-semibold leading-9'>Software Implementation</h1>
                         <Link to='/service/academic/softwareimplementation' className='text-[15px]'>Read more</Link> 
                    </div>
                    <div onClick={()=>navigate('/service/academic/articlewriting')}
                     className=' w-[300px] h-[150px] flex  flex-col justify-center  border-2 border-[#ddd] border-solid hover:bg-[#2d3092] hover:text-white cursor-pointer px-[20px]'>
                        <h1 className='font-space text-[20px] font-semibold leading-9'>Article Writing</h1>
                    <Link to='/service/academic/articlewriting' className='text-[25px] text-blue-500 hover:text-white'><HiOutlineArrowNarrowRight className='animate-bounce '/></Link> 
                    </div>
                    <div className=' w-[300px] h-[150px] flex flex-col justify-center  border-2 border-[#ddd] border-solid hover:bg-[#2d3092] hover:text-white cursor-pointer px-[20px]'>
                        <h1 className='font-space text-[20px] font-semibold leading-9'>Article Publication</h1>
                     <Link to='/service/academic/articlepublication' className='text-[15px]'>Read more</Link> 
                    </div>
                    <div className=' w-[300px] h-[150px] flex flex-col justify-center border-2 border-[#ddd] border-solid hover:bg-[#2d3092] hover:text-white cursor-pointer px-[20px]'>
                        <h1 className='font-space text-[20px] font-semibold leading-9'>Thesis Writing </h1>
                         <Link to='/service/academic/thesiswriting' className='text-[15px]'>Read more</Link> 
                    </div>
                    <div className=' w-[300px] h-[150px] flex flex-col justify-center  border-2 border-[#ddd] border-solid hover:bg-[#2d3092] hover:text-white cursor-pointer px-[20px]'>
                        <h1 className='font-space text-[20px] font-semibold'>Presentation</h1>
                         <Link to='/service/academic/presentation' className='text-[15px]'>Read more</Link> 
                    </div>

                   


                </div>

            </section>
            
        </div>
    )
}
