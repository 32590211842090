import React from 'react'
import { Link } from 'react-router-dom'
import {HiPhone} from 'react-icons/hi'
export default function Footer() {
  return (
    <footer className='bg-[#FFC023] w-full py-10  flex flex-col   '>
            <div className= 'md:mx-auto md:w-[80%] px-3 gap-y-3 '>
                   <div className='bg-transparent px-2 font-extrabold w-50%'>
                       <h1 className='font-space'>MREC</h1>
                       <span className='font-space'>Communicate.  Research.  Innovate</span>
                   </div>
                   <div className='grid  md:grid-cols-3 mt-5 gap-y-3 '>
                     <div className='bg-transparent leading-6 px-2'>
                       <h3 className='mb-3 text-[20px] font-bold font-space'>Contact</h3>
                       <ul className='font-Roboto md:text-[16px]'>
                       <li>165, KKR Building </li>
                       <li>Sankaran Pillai Road</li>
                       <li>Tiruchirappalli, Tamilnadu  621005</li>
                       </ul>
                     </div>

                     <div className='bg-transparent w-[180px] leading-6 px-2'>
                       <h3 className='mb-3 text-[20px] font-bold font-space'>Quick Links</h3>
                        <ul className='md:text-[16px] font-Roboto'>
                       <li className='hover:md:underline'><Link to='service/academic'>Academic reasearch </Link></li>
                       <li className='hover:md:underline'><Link to='service/fundingproposal'>Funding Propasal</Link></li>
                       <li className='hover:md:underline'><Link to='service/patentingservice'>Patenting Service</Link></li>
                       <li className='hover:md:underline'><Link to='service/academicproject'>Academic Projects</Link></li>
                       </ul>
                     </div>
                     <div className=''>
                            <h3 className='mb-3 text-[20px] font-bold font-space'>Mobile No</h3>
                            <p className='md:text-[20px] flex items-center gap-x-2  cursor-pointer tracking-wider'> <HiPhone  className='w-[30px] text-white bg-black h-[30px] text-[10px]  rounded-2xl  px-2 '/> 9894022944</p>
                    </div> 
                    </div>                   

            </div>
    </footer>
  )
}

