import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'

const steps = [
    {
        point: " Research-driven: Behind every impactful article lies meticulous research, ensuring that information is credible, relevant, and timely."

    },
    {
        point: "Audience-centric: Tailoring content to resonate with the intended readership, ensuring engagement and comprehension"

    },
    {
        point: " Narrative Flow: Ensuring a seamless transition from introduction to conclusion, guiding readers smoothly through the article's essence"

    },
    {
        point: "Originality: Crafting unique perspectives and insights, setting the content apart in a sea of information"

    },

]
export default function Articlewriting() {
    return (
        <div className='w-full flex flex-col h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px]   font-bold'>Article writing</h1>
                </div>
            </div>

            <section className='w-full flex flex-col md:py-[1rem] px-5'>
                <div className='w-full md:mx-auto  py-[1rem]  text-justify bg-white md:w-[80%] '>
                    <h1 className='text-[20px] font-Roboto'>
                        Article Writing: Crafting Narratives that Inform, Inspire, and Influence </h1>
                    <p className='leading-7   md:px-0 px-[1rem]  md:py-2 text-justify text-[15px]'>
                        The written word possesses immense power. In a world inundated with fleeting visuals and transient sounds, well-crafted articles stand as
                        enduring testaments to knowledge, ideas, and insights.
                        Article writing is the art and science of weaving words into compelling narratives that captivate, educate, and provoke thought.
                    </p>


                    <div className=' py-[2rem] flex md:flex-row w-full flex-col'>
                        <div className='md:w-[50%]'>
                            <h1 className='px-2 md:px-0 font-Roboto font-bold md:text-xl'>The Essence of Effective Article Writing</h1>
                        </div>
                        <div className='md:w-[60%] flex flex-col  md:gap-y-4 text-[16px] mt-2 md:mt-0  divide-y-2'>

                            <div className='flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#F7AD19] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='' size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Research-driven</span>
                                    <p>Behind every impactful article lies meticulous research, ensuring that information is credible, relevant, and timely
                                    </p>
                                </div>
                            </div >

                            <div className='flex md:flex-row flex-col items-start  md:items-center md:gap-x-7  gap-y-4   p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#F7AD19] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken size={20} className="" />
                                </div>
                                <div className='flex flex-col gap-y-3 '>
                                    <span className='text-xl '>Audience-centric</span>
                                    <p>
                                    Tailoring content to resonate with the intended readership, ensuring engagement and comprehension
                                    </p>
                                </div>

                            </div>

                            <div className='flex md:flex-row flex-col items-start  md:items-center md:gap-x-7  gap-y-4   p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#F7AD19] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken size={20} className="" />
                                </div>
                                <div className='flex flex-col gap-y-3 '>
                                    <span className='text-xl '>Narrative Flow</span>
                                    <p>
                                    Ensuring a seamless transition from introduction to conclusion, guiding readers smoothly through the article's essence.
                                    </p>
                                </div>

                            </div>
                            <div className='flex md:flex-row flex-col items-start  md:items-center md:gap-x-7  gap-y-4   p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#F7AD19] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken size={20} className="" />
                                </div>
                                <div className='flex flex-col gap-y-3 '>
                                    <span className='text-xl'>Originality</span>
                                    <p>
                                       Crafting unique perspectives and insights, setting the content apart in a sea of information.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='w-full flex'>                        
                        <div className='w-[60%]'>
                        <ul className='text-[16px] leading-8 mt-2'>
                            <li><span className='bg-[#F7AD19] text-[20px] p-1 rounded'>Informative</span> Delve deep into subjects, offering readers comprehensive insights.</li>
                            <li>Opinion Pieces: Share perspectives, critiques, and viewpoints on contemporary issues.</li>
                            <li>How-to & Guides: Provide step-by-step instructions or guides on various topics.
                            </li>
                            <li>Interviews & Profiles: Shine a spotlight on personalities, sharing their stories, experiences, and expertise.
                            </li>
                        </ul>

                        <p className='mt-2 text-[16px] leading-8'>
                            In the vast digital expanse, articles are more than just text; they're beacons of knowledge, reflecting expertise and
                            passion. With MREC's unwavering commitment to excellence, your articles are set to leave a lasting impact.
                        </p>
                        </div>

                        <div className='w-[50%] flex items-center  justify-end'>
                            <h1 className='font-Roboto font-bold text-[24px]'>Types of Articles We Support</h1>
                        </div>
                    </div>

                   
                    <div className=' py-[1rem]'>
                        <h1 className='font-bold mt-1'>MREC's Dedication to Article Writing</h1>
                        <p className='mt-1'>At MREC, we recognize the importance of articulating ideas with precision, clarity, and flair.</p>
                        <ul className='list-disc text-[16px] leading-8 mt-1'>
                            <li>Expert Collaborations: Our team consists of seasoned writers, researchers, and editors, ensuring your articles meet the highest standards.
                            </li>
                            <li>
                                Customized Workshops: Engage in sessions focusing on writing styles, research methods, and effective communication tailored to the art of article writing.

                            </li>
                            <li>
                                Publication Assistance: From identifying suitable platforms to ensuring compliance with publication guidelines, we provide end-to-end support.

                            </li>
                            <li>
                                Feedback & Review: Benefit from constructive critiques, helping refine and elevate your content.

                            </li>
                        </ul>
                    </div>

                    <div className='py-[1rem]'>
                        <h1 className='font-Roboto font-bold'>Quality and Originality at the Forefront</h1>
                        <p className='text-[16px] leading-8'>
                            In today's digital age, uniqueness and authenticity hold paramount importance.
                            At MREC, we're not just committed to crafting compelling narratives but also ensuring their originality.
                            Every article crafted under our guidance undergoes rigorous scrutiny using reputed plagiarism checking tools.
                            We proudly guarantee content with below 10% plagiarism, ensuring that what you receive is genuinely distinctive and adheres to the highest standards of academic and professional
                            integrity.

                        </p>
                    </div>
                </div>
            </section>

        </div>
    )
}
