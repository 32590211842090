import Header from "./Navbar/Header";
import Hero from "./Hero";
import React from 'react'

export default function Home() {
  return (
    <div className="flex flex-col flex-shrink items-center">
        {/* <Header/>  */}
        <Hero/>
    </div>
  )
}
