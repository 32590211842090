import React from 'react'
//import image5 from './image5.webp'
import { Link} from 'react-router-dom'
export default function Service() {
    
    return (
        <>
            <div className='flex flex-col h-full  w-full bg-white items-center'>
                <div className='md:mx-auto h-full md:mt-7 py-5 md:w-[90%]  flex flex-col items-center '>
                    <h1 className='text-center mb-9 text-[36px] font-Roboto'>What we offer</h1>
                    <img src="https://t3.ftcdn.net/jpg/06/08/82/50/360_F_608825085_MuQopoWCJQJ8BUa4u2z1DECXtkuoVLmj.jpg" alt="service" className='md:h-[500px] h-[200px] w-[80%] rounded-md' />
                </div>

                {/* <div className='py-16  px-2  h-full bg-white text-center'>
                    <div className='md:mx-auto md:w-[50%] px-4'>
                     <p className='text-[18px] font-space'>
                            This is your Services Page. It's a great opportunity to provide information about the services you provide. Double click on the text box to start editing your content and make sure to add all
                            the relevant details you want to share with site visitors.
                        </p> 
                    </div>
                </div> */}

                <section className='bg-white md:w-[90%] md:mx-auto py-10 px-8 '>
                    <div className='md:flex gap-2  md:flex-row flex-col'>

                        <div className='bg-transparent md:w-[30%]'>
                            <h1 className='font-raleway font-bold text-[36px]'>Our Services</h1>
                        </div>

                        <div className='md:w-[70%] bg-transparent grid md:grid-cols-2 w-full'>
                            <div className='flex flex-col md:w-[400px] md:px-10 text-left mt-3 md:mt-0'>
                                {/* <span className='font-bold'>01</span> */}
                                <h2 className='font-Roboto  font-bold text-[20px] md:mb-5 '>Academic Research</h2>
                                <p className='text-[16px] leading-8 text-justify font-Roboto'>Academic research requires doing a deep analysis of the subject being studied.
                                    The scholars will need to investigate and evaluate scholarly sources to successfully
                                    finish the documentation that has been given by the instructor or guide.
                                    </p>
                                 <Link to="/service/academic" className='decoration-none text-blue-500 md:mt-3'> Read More</Link> 

                            </div>
                            <div className='flex flex-col md:w-[80%] md:px-10 text-justify pb-10 mt-3 md:mt-0'>
                                {/* <span className='font-bold'>02</span> */}
                                <h2 className='font-Roboto font-bold text-justify text-[20px] md:mb-5'>Funding Proposals</h2>
                                <p className='text-[16px] leading-8 font-Roboto'>
                                Prepare a compliant and detailed grant to receive funding with the assistance of MREC; 
                                improve your chances of receiving funds with a complete research grant proposal 
                                created by MREC's staff of writers and editors.
                                    </p>
                                <Link to="/service/fundingproposal" className='decoration-none text-blue-500 md:mt-3'>Read More</Link> 
                            </div>
                            <div className='flex flex-col md:w-[80%] md:px-10  mt-0 md:my-2'>
                                {/* <span className='font-bold'>03</span> */}
                                <h2 className='font-Roboto font-bold text-[20px] md:mb-5'>Patenting Services</h2>
                                <p className='text-[16px] leading-8 font-Roboto text-justify'>
                                Protecting the intellectual property of individuals is essential and appropriate for 
                                fostering growth and innovation in a sector. MREC's Patent Services are unparalleled 
                                since they are provided by top Patent Agents with experience across a wide range of 
                                businesses and practise areas. Our target audience consists of bright young minds 
                                from colleges and universities around the country. We encourage them to submit their 
                                work for publication.
                                </p>
                                 <Link to='/service/patentingservice' className=' text-blue-700 md:mt-3'>Read More</Link> 
                            </div>
                            <div className='flex flex-col md:w-[80%] md:px-10  mt-3 md:mt-0 mb-6'>
                                {/* <span className='font-bold'>04</span> */}
                                <h2 className='font-Roboto font-bold text-[20px] md:mb-5'>Academic Projects</h2>
                                <p className='text-[16px] leading-8 font-Roboto text-justify'>
                                Academic projects are student research projects that are carried out as part of a student's course,
                                 degree, or study. MREC supports major and mini projects. The student will get exposure on the cutting-edge technologies. 
                                 The outcome of the project report will help them to grab better opportunities in the industries.
                                </p>
                             <Link to='/service/academicproject' className='text-blue-700 md:mt-3'>Read More</Link> 
                            </div>
                            <div className='flex flex-col md:w-[80%] md:px-10'>
                                {/* <span className='font-bold'>05</span> */}
                                <h2 className='font-Roboto font-bold text-[20px] md:mb-5'>Courses</h2>
                                <p className='text-[16px] leading-8 font-Roboto text-justify'>
                                The Victorylance Training Institute in Trichy is a new establishment with the goal of creating successful 
                                business owners and other skilled workers. All of the examples used in our trainings are genuine.
                                 Whether you're a recent graduate, an entry-level employee, or a seasoned professional, 
                                our team of industry experts and corporate trainers can help you achieve your professional goals.
                                </p>
                                 <Link to='courses' className=' text-blue-700 md:mt-3'>Read More</Link>

                            </div>
                            <div className='flex flex-col md:w-[80%] md:px-10 text-justify mt-3'>
                                {/* <span className='font-bold'>06</span> */}
                                <h2 className='font-Roboto font-bold text-[20px] md:mb-5'>Internships</h2>

                                <p className='text-[16px] leading-8 font-Roboto'>
                                Are you currently enrolled at a university or college? We are pleased to provide both 
                                paid and unpaid internship opportunities. The student will gain hands-on experience of
                                 how the relevant industry operates through participation in an internship.
                                </p>

                                 <Link to='internships' className='decoration-none text-blue-700 md:mt-3'>Read More</Link> 
                            </div>

                        </div>
                    </div>
                </section>

                {/* <section className='md:mx-auto bg-slate-500 md:w-[90%] py-10'>
                           <div className='text-center '>
                               <h1>Testimonals</h1>
                           </div>
                </section> */}

                <section className='md:mx-auto'>
                </section>
                 
            </div>

        
            
        </>

        
    )
}
