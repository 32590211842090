import React from 'react'
import { useNavigate } from 'react-router-dom'
export default function Funding() {
    const navigate=useNavigate()
    return (
        <div className='h-full w-full flex flex-col bg-white ' >
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Funding Proposals</h1>
                </div>
            </div>

            <section className='w-full'>
                <div className='md:mx-auto md:w-[80%] flex md:flex-row flex-col-reverse items-center justify-center gap-x-5 py-0 md:py-10 px-3'>
                    <div className='md:w-[50%] w-full'>
                        {/* <h1>Image</h1> */}
                    </div>
                    <div className='md:w-[50%] max-w-full md:px-7 font-popins leading-9 text-justify font-thin'>
                        <p>
                            A research project is a costly undertaking that necessitates funding for
                            everything from equipment to technical experts to conference travel and publication costs;
                            therefore, obtaining financial support in the form of a grant is critical.
                            Once the funding is completed, it will assist you in broadening the scope of your research or even forming new alliances and working
                            on new research initiatives.
                        </p>
                        <p>
                            Obtaining a research funding in the first place might be difficult due to strong demand; the process of preparing a grant can be difficult, especially for inexperienced researchers.
                            Whether you're applying to the state or federal government, a funding agency, or even your own school, your proposal must be professionally written and persuasive enough to persuade
                            the reviewer or selection panel
                            to choose you above other qualified candidates.
                        </p>
                        <p>
                            MREC authors understand how important it is to acquire funding. We also understand how tough and time-consuming writing a grant can be, which is why we provide extensive grant writing assistance to alleviate the stress
                        </p>
                    </div>
                </div>
            </section>
  
            <section className='w-full '>
                   <div className='md:w-[80%] md:mx-auto flex flex-col md:flex-row justify-center md:text-[36px] font-Roboto hover:cursor-pointer py-5 gap-y-3'>
                           <div  /* onClick={()=>navigate('/proposalwritting')} */
                            className=' md:flex-grow flex-shrink  flex items-center justify-center bg-slate-50 hmd:h-[150px]'>
                                <h2 >How to write Proposals?</h2>
                           </div>
                           <div  /*  onClick={()=>navigate('/fundingagency')} */
                           className='flex flex-grow items-center justify-center bg-slate-100'>
                               <h2>Funding Agencies</h2>
                           </div>
                   </div>
            </section>

        </div>
    )
}
