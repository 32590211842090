export const Menu=[

    {
            title:"About",
            url:"/aboutus"
    },
    {
      title:'Services',
      url:"/service"  ,
      submenu:[
        {  title:'Academic Research',
           url:'/service/academic'
    },{
        title:"Funding Proposals",
         url:"/service/fundingproposal"
    },{
        title:"Patenting Service",
        url:"/service/patentingservice"
    },{
        title:"Academic Projects",
        url:"/service/academicproject"
    },{
        title:"Courses",
        url:"/service/courses"
    },{
        title:"Internship",
        url:"/service/internships"
    }

      ]
    },{
        title:"Process",
        url:'/process'
    }
]