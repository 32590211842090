import React from 'react'
import mission from './mission1.avif'
import vision from './standsfor.avif'
import { GoLightBulb } from "react-icons/go";
//import {  } from "react-icons/bs";
import { BsGlobe } from "react-icons/bs";
//import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import { BiSearchAlt2}  from "react-icons/bi";
import {MdOutlineArrowDropDown} from "react-icons/md"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


const data=[
    {
        icon:<BiSearchAlt2/>,
        title:"Simplicity",
        description:"Navigating the intricacies of research shouldn’t be daunting. We aim to streamline this journey for you."
    },
    {
        icon:<GoLightBulb/>,
        title:"Creativity",
        description:"Break free from traditional constraints and let your research ideas soar."
    },
    {
        icon:<BsGlobe/>,
        title:"Community",
        description:"We are committed to finding simple yet impactful solutions to pressing challenges affecting our community." 
    }
]
export default function LandingPage() {
  return (
    <>
     <div className='md:mx-auto  w-full md:w-[80%] grid md:grid-cols-2 bg-white md:py-[1rem] px-2 md:gap-x-2 gap-y-4' 
    >
        <div className='flex flex-col justify-center  gap-y-3'>
             <h1 className='font-bold text-3xl font-Roboto'>Our Mission</h1>  
            <p  className='text-[20px] font-normal font-Roboto'>
               To revolutionize academic research by making it more accessible, 
               innovative, and straightforward.

            </p>
        </div>

        <div className=' flex  justify-center'>
            <img src={mission} alt="mission" className='md:w-[360px] object-cover'/>
        </div>
    </div>


    <div className=' md:mx-auto  w-full md:w-[80%] grid md:grid-cols-2 bg-white md:py-[3rem] px-2 md:gap-x-2 gap-y-4'>
          <div className='hidden md:flex justify-start'>
              <img src={vision} alt="vision" className=' md:w-[360px] object-cover'/>
          </div>  
          <div className='md:w-[90%] w-full'>
            <h1 className='font-bold md:text-2xl font-Roboto'>What We Stand For</h1>
            <Accordion 
              allowMultipleExpanded={false}
              preExpanded={[0]}
              className="mt-8 border-none"
            >
                 {
                    data.map((item,i)=>{
                        return(
                            <AccordionItem key={i} uuid={i} className="bg-white border-2 md:rounded-lg overflow-hidden md:mt-5">
                                <AccordionItemHeading>
                                    <AccordionItemButton className='bg-white p-4 w-full  flex justify-between cursor-pointer'>
                                         <div className='p-[10px] bg-[#F7AD19] rounded-[5px] text-[] '>
                                            {item.icon}
                                         </div>
                                         <span className='font-bold font-Roboto'>{item.title}</span>
                                         <div className='p-[10px] bg-[#F27F0C] rounded-[5px] text-white'><MdOutlineArrowDropDown size={20}/></div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                      <p className='font-Roboto text-[16px]'>{item.description}</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        )
                    })
                 }

            </Accordion>
          </div>
    </div>
    </>
  )
}

