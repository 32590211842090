import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'

export default function Softwareimp() {
    return (
        <div className='flex flex-col w-full h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Software Implementation</h1>
                </div>
            </div>

            <section className='w-full py-1 '>
                <div className='md:w-[80%] w-full md:mx-auto md:py-[1rem] text-justify bg-white'>
                    <h1 className='font-bold'></h1>
                    <p className='leading-9 font-popins px-[1rem] md:px-3  md:py-3 text-justify text-[16px]'>
                        Software implementation is where abstract concepts and designs evolve into tangible,
                        working applications. It's the bridge between theoretical design and practical utility.
                        With the rise of cutting-edge technologies and sophisticated simulation tools,
                        this process has never been more dynamic and potent.
                    </p>


                    <div className='px-[1rem] md:px-0 py-[2rem] text-justify'>
                        <h1 className='font-Roboto md:text-2xl'>The Power of Cutting-Edge Technologies</h1>
                        <p className='text-sm mt-2'>
                            Today's software landscape is defined by rapid innovation and transformation.
                            From AI-driven algorithms to blockchain's decentralized systems, cutting-edge technologies are shaping the future.
                        </p>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 pt-10'>
                            <div className='flex flex-col gap-y-4 text-[16px] text-justify'>
                                <div className='md:w-[80%] flex flex-col gap-y-4 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className="text-white text-2xl">AI & Machine Learning</span>
                                    <p className='text-white'>   Tailoring experiences, predicting trends, and automating processes with unparalleled precision
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className="text-white text-2xl">Blockchain</span>
                                    <p className='text-white'>
                                        Crafting transparent, secure, and decentralized systems for a myriad of applications.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col md:items-end gap-y-4 text-[16px] text-justify'>
                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96] flex-1 '>
                                    <span className="text-white text-2xl">IoT (Internet of Things)</span>
                                    <p className='text-white'>
                                        Connecting devices and systems, enabling smarter, interconnected ecosystems
                                    </p>

                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className="text-white text-2xl">Cloud Computing</span>
                                    <p className='text-white'>
                                        Ensuring scalability, flexibility, and accessibility, anytime and anywhere.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='md:px-0 px-2 py-[2rem]'>
                        <p className='px-3 md:px-0 '>Before deployment, it's crucial to understand how software will behave in real-world scenarios. Simulation tools provide that sandbox environment.
                        </p>
                        <div className=' mt-3 w-full text-justify flex md:flex-row flex-col-reverse'>

                            <div className='md:w-[50%] flex flex-col  gap-y-4 text-[16px]  divide-y-2'>
                                <div className='px-3 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                    <div  className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                        <GiThreePointedShuriken  className='text-[#652ba7]'size={20}/>
                                    </div>
                                    <div  className='flex flex-col gap-y-3'>
                                        <span  className='text-xl'>Realistic Scenarios</span>
                                        <p>
                                            Mimic real-world conditions to understand software reactions
                                        </p>
                                    </div>
                                </div>
                                <div className='px-3 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                    <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                        <GiThreePointedShuriken  className='text-[#652ba7]  'size={20}/>
                                    </div>
                                    <div  className='flex flex-col gap-y-3'>
                                        <span  className='text-xl'>Performance Metrics</span>
                                        <p>
                                            Gather data on speed, responsiveness, and resource utilization.

                                        </p>
                                    </div>
                                </div>
                                <div className='px-3 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                    <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                        <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                    </div>
                                    <div  className='flex flex-col gap-y-3'>
                                        <span  className='text-xl'>Iterative Refinement</span>
                                        <p>
                                            Adjust and enhance software based on simulation results for optimal performance.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='md:w-[50%] flex items-center justify-end'>
                                <h1 className='font-Roboto md:text-2xl'>Simulation Tools: The Testing Ground</h1>
                            </div>
                        </div>

                    </div>

                    <div className=' px-[1rem] md:px-0 py-[2rem] text-justify flex md:flex-row flex-col'>

                        <div className='md:w-[50%] flex items-center'>
                            <h1 className='font-Roboto md:text-xl'>MREC's Commitment to Software Implementation</h1>
                        </div>
                        <div className='md:w-[60%] flex flex-col gap-y-4 text-[16px] divide-y-2'>
                            <p className='md:text-[14px]'>
                            Venturing into the world of software requires more than just a vision; 
                            it demands expertise, precision, and the right tools.

                            </p>
                            <div className='px-3 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Expert Guidance</span>
                                    <p>
                                        Our team of professionals brings a wealth of experience in diverse technological arenas, ensuring your software is primed for success.
                                    </p>
                                </div>
                            </div >

                            <div className='px-3 mt-2 flex   md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3' >
                                    <span className='text-xl'>Access to Tools</span>
                                    <p>
                                        MREC offers an array of simulation tools, ensuring your software is tested, refined, and ready
                                    </p>
                                </div>

                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Continuous Learning</span>
                                    <p>
                                        With a rapidly evolving tech landscape, our workshops and seminars keep you abreast of the latest in software implementation trends
                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-7 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Building Credibility:</span>
                                    <p>
                                        A well-researched literature section shows rigor in your approach, earning trust from peers and readers alike.
                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4  p-9 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Guiding Methodology</span>
                                    <p>
                                        By analyzing previous methodologies, you can refine and perfect your own, ensuring higher accuracy and relevance
                                    </p>
                                </div>
                            </div>
                            <p  className='px-[1rem] pt-4 leading-6 md:text-[14px]'>
                            In the realm of software, the journey from idea to implementation is intricate,
                             challenging, and profoundly rewarding. With MREC's support, combined with cutting-edge technologies
                             and sophisticated simulation tools, your software vision is destined for greatness
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
