import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'
export default function ProblemIde() {
    return (
        <div className='flex flex-col w-full h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Problem Identification</h1>
                </div>
            </div>

            <section className='w-full'>
                <div className='md:w-[80%] w-full md:mx-auto  py-[1rem]  text-justify bg-white '>
                    <div className='md:h-[300px] md:flex'>
                     <div className='md:w-[50%] flex flex-col justify-center'>  
                    <h1 className='font-Roboto  font-bold md:text-[24px] px-[1rem]  md:px-0 '>Problem Identification: The Heartbeat of Academic Research</h1>
                    <p className='leading-7   md:px-0 px-[1rem]  md:py-5 text-justify text-[15px]'>
                        Every monumental academic discovery began with a singular, pressing question.
                        This question, or the research problem, becomes the guiding star of the research voyage.
                        At its core, problem identification isn't just about pinpointing an issue but understanding its depth, relevance, and implications.
                    </p>
                    </div> 
                    </div>
                    <div className='py-[2rem]  w-full  flex md:flex-row flex-col'>
                        <div className='md:w-[50%]'>
                            <h1 className='px-2 md:px-0 font-Roboto font-bold md:text-xl'>Why is Problem Identification Crucial?</h1>
                        </div>
                        <div className='md:w-[60%] flex flex-col  md:gap-y-4 text-[16px] mt-2 md:mt-0  divide-y-2'>
                            <div className='flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-9 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#F7AD19] rounded-[10%] p-2 flex items-center shadow-md'>
                                     <GiThreePointedShuriken className=''size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Direction</span>
                                    <p>It provides a clear path and objective for the research, ensuring that efforts are streampned towards a defined goal.
                                    </p>
                                </div>
                            </div >

                            <div className='flex md:flex-row flex-col items-start  md:items-center md:gap-x-7  gap-y-4   p-9 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#F7AD19] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken size={20} className="" />
                                </div>
                                <div className='flex flex-col gap-y-3 '>
                                    <span className='text-xl '>Relevance</span>
                                    <p>
                                        By identifying a pertinent problem, the research holds contemporary significance, making findings more impactful.
                                    </p>
                                </div>

                            </div>

                            <div className='flex md:flex-row flex-col items-start md:items-center  md:gap-x-7  gap-y-4  p-9 overflow-hidden rounded-[0px]'>
                                <div className='rounded-[10%] bg-[#F7AD19] p-2 flex items-center shadow-md '>
                                    <GiThreePointedShuriken size={20}  className=''/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl '>Scope Definition</span>
                                    <p className=''>
                                        Understanding the problem helps in setting boundaries, ensuring that the research remains focused and manageable.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='md:py-[2rem] text-justify px-[1rem] md:px-0   '>
                        <h1 className='md:text-[24px] font-bold font-Roboto'>Characteristics of a Well-Defined Research Problem</h1>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-y-3   font-semibold pt-10  '>
                            <div className='flex flex-col gap-y-4 text-[16px] text-justify hover:cursor-pointer '>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#EE6C4D]'> 
                                    <span className='text-white font-Roboto tracking-wide text-2xl'>Specificity</span>
                                    <p className='text-white'> While it should capture the essence of the issue, brevity is key. A well-defined problem is specific and unambiguous.
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#293241] '>
                                    <span className="text-white text-2xl"> Relevance</span>
                                    <p className='text-white'>
                                        It should resonate with the current academic landscape, addressing a genuine gap or challenge.
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col md:items-end gap-y-4 text-[16px] text-justify hover:cursor-pointer divide-y-2 '>
                                <div className='md:w-[80%]   flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#3D5A80] flex-1 '>
                                    <span className='text-white text-2xl'> Feasibility</span>
                                    <p className='text-white'>
                                        Research problem should be challenging yet achievable, striking a balance between ambition and practicality.
                                    </p>
                                </div>

                                <div className='md:w-[80%]  flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#F27F0C] md:flex-1 '>
                                    <span className='text-white text-2xl'> Evidence-based</span>
                                    <p className='text-white'>
                                        Grounding your problem in existing literature or observations lends it credibility.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-full px-[1rem]   '>
                    <div className='py-[3rem] md:w-[70%] md:mx-auto  divide-y-4'>
                        <h1 className='font-Roboto text-[24px] text-center'>Our Role in Your Journey</h1>
                        <p className='mt-5 text-[16px] leading-7 text-justify'>
                            At MREC, we understand that identifying the right research problem can be daunting.
                            Our expertise lies in guiding academic researchers through this crucial phase.
                            Through consultation, literature review support, and insights from our vast academic network, we ensure that your research starts on the strongest footing.

                        </p>
                    </div>
                    </div>

                    <div className='w-full py-[1rem] md:py-[3rem] px-[1rem] md:px-0 '>
                        <h1 className='font-Roboto md:text-2xl'>Tips for Problem Identification</h1>
                        <div className='leading-7 text-[16px] flex  md:flex-row flex-col gap-y-4 justify-evenly text-justify mt-2'>
                            <div className='md:w-[300px]  border-2 border-black/[50%] rounded-xl p-8  hover:shadow-lg cursor-pointer flex flex-col gap-y-3'>
                                <span className='text-[#361968] text-[18px]'>Dive Deep into Literature</span>
                               <p className='text-[14px]'>Understand what's already out there. Where do the gaps lie?
                               </p>
                            </div>
                            <div className='md:w-[300px] border-2 border-black/[50%] rounded-xl p-8 hover:shadow-lg cursor-pointer flex flex-col gap-y-3'>
                                <span className='text-[#361968] text-[18px]'>Discuss with Peers</span>
                            <p className='text-[14px]'>
                                Engaging in academic discussions can spark new perspectives and ideas.                                      
                            </p>
                            </div>
                            <div className='md:w-[300px] border-2 border-black/[50%] rounded-xl  p-8 hover:shadow-lg cursor-pointer flex flex-col gap-y-3'>
                                <span className='text-[#361968] text-[18px]'>Stay Curious</span>
                            <p className='text-[14px]'>
                                Sometimes, the most pressing problems arise from simple observations and innate curiosity.

                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
