import React, { useState } from 'react'
import { HiMenu } from "react-icons/hi";
import { Menu } from './menu';
import { motion } from "framer-motion";
import {BsChevronDown} from 'react-icons/bs'
import logo from '../../Component/1.png'
import MenuItem from './MenuItem';

import { MdOutlineClose } from "react-icons/md";
import { Link ,useNavigate} from 'react-router-dom'


export default function Header() {
    const [nav, setNav] = useState(false)
    const [mobiledrop ,setMobileDrop]=useState(false)
    const navigate=useNavigate()

    const handleClick = () => setNav(!nav)

    const handleClose = () => setNav(!nav)
    return (
        <div className='w-full md:w-full h-[80px] z-0 bg-white sticky top-0 drop-shadow-lg'>
            <div className='md:mx-auto px-2 flex justify-between items-center md:w-[80%] h-full'>
                <div className='flex justify-center    items-center  gap-x-3 md:gap-3   cursor-pointer' onClick={()=>navigate('/')} >
                    <div>
                        <img src={logo} alt="logo" width="45px" height="10px" className=' h-[50px] ' />
                    </div>


                    <div className=' text-left text-[15px] md:text-[13px] md:mt-1 font-semibold font-space'>
                        <h1 className='md:text-[34px] text-[18px] font-bold font-space'>MREC</h1>
                        <span className='mr-1'>Communicate. </span>
                        <span className='mr-1'>Research. </span>
                        <span>Innovate. </span>
                    </div>
                </div>
                   
                    <div className='hidden md:flex gap-x-4 font-Roboto '>                        
                    {Menu.map((item,index)=>{
                            
                            return(
                                <MenuItem items={item} key={index}/>
                            )
                    })}                         
                    </div>
                   
                <div onClick={handleClick} className="text-4xl">
                    {!nav ? <HiMenu className='w-5  md:hidden'/> : ""}
                </div>
            </div>

            <div className={!nav ? 'hidden':'absolute bg-white w-full px-8  z-50 table text-[18px] h-[100vh] top-0 item-center md:hidden'}>

                <div className='fixed right-[10%] top-6' onClick={handleClose}>
                    {nav ? <MdOutlineClose className='w-5 md:hidden' /> : ""}
                </div>
                <ul className='mt-16 font-Roboto'>

                 {
                    Menu.map((item ,index)=>(
                    <li className='relative py-2 bg-white '>
                       {item.submenu ? ( 
                        <>
                          <Link  to={item.url}  key={index}   className='flex gap-x-3 items-center '>
                              {item.title}
                              <BsChevronDown onClick={() => setMobileDrop((prev) => !prev)}/>
                       </Link> 

                       <div className={mobiledrop ?'top-[58%] py-0 w-64  bg-white':'hidden'}>
                                <motion.ul 
                                animate={
                                    mobiledrop
                                      ? {
                                          height: "fit-content",
                                        }
                                      : {
                                          height: 0,
                                        }
                                  }
                                  className="flex h-0 flex-col  text-[1rem] overflow-hidden"
                                >
                                    {item.submenu.map((sub,index)=>(
                                       <li className=' py-2 text-sm px-2' key={index}>
                                          <Link to={sub.url} onClick={handleClose}>{sub.title}</Link>
                                       </li>  
                                    ))

                                    }
                                </motion.ul>
                       </div>
                       </>
                    
                       ):(
                            <Link to={item.url} onClick={handleClose}>{item.title}</Link> 
                       )}
                   </li>
                         ))
                 }

                </ul>
            </div>

        </div>
    )
}
