import React from 'react'
import { Link } from 'react-router-dom'
export default function Patenting() {
    return (
        <div className='w-full flex flex-col h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Patenting Service</h1>
                </div>
            </div>

            <section className='w-full md:py-10  px-5'>
                <div className='md:w-[80%] w-full md:mx-auto '>
                    <div className='mb-5'>
                        <h1 className='md:text-[30px] text-[20px] font-space font-bold mb-3'>what is a patent?</h1>
                        <p className='text-[18px] tracking-wide leading-8 font-popins text-justify'>
                            A patent is an exclusive right granted for an invention, which is a product or a process that provides, in general,
                             a new way of doing something, or offers a new technical solution to a problem. 
                            To get a patent, technical information about the invention must be disclosed to the public in a patent application.<br></br>
                            <Link to="https://www.wipo.int/patents/en/#:~:text=A%20patent%20is%20an%20exclusive,public%20in%20a%20patent%20application" target="_blank" className='hover:underline text-blue-400 leading-'>(Source: Patents (wipo.int))</Link>
                        </p>
                    </div>

                    <div className='mb-5 w-full'>
                        <h1 className='md:text-[30px] text-[20px] font-space font-bold mb-3'>
                            What kind of protection does a patent offer?
                        </h1>
                        <p className='text-[18px] tracking-wide leading-8 font-popins text-justify'>
                            In principle, the patent owner has the exclusive right to prevent or stop others from
                            commercially exploiting the patented invention. In other words, patent protection means that the invention cannot
                            be commercially made, used, distributed, imported or sold by others without the patent owner's consent
                        </p>
                    </div>

                    <div className='mb-5'>
                        <h1 className='md:text-[30px] text-[20px] font-space font-bold mb-3 text-justify'>
                            Is a patent valid in every country?
                        </h1>

                        <p className='text-[18px]  tracking-wide leading-8 font-popins'>
                            Patents are territorial rights. In general, the exclusive rights are only applicable in the country or region in which a patent has been filed and granted, in accordance
                            with the law of that country or region.
                        </p>
                    </div>

                    <div>

                        <h1 className='md:text-[30px] text-[20px] font-space font-bold mb-3'>How long does a patent last?</h1>
                        <p className='text-[18px] tracking-wide leading-8 font-popins'>
                            The protection is granted for a limited period, generally 20 years from the filing date of the application.

                        </p>
                    </div>


                </div>

            </section>
        </div>
    )
}
