
import './App.css';

import { BrowserRouter, Routes,Route } from 'react-router-dom';
import ScrollTop from './Component/scrollTop';
//import Hero from './Component/Hero';
import Header from './Component/Navbar/Header';
import Home from './Component/Home';
import Service from './Component/Service';
import AboutUs from './Component/Aboutus';
import Academic from './Component/Detailpage/Academic';
import Funding from './Component/Detailpage/Funding';
import Footer from './Component/Footer';
import Blog from './Component/Blog';
import Patenting from './Component/Detailpage/Patenting';
import BlogDetail from './Component/Blog/BlogDetail';
import AcaProject from './Component/Detailpage/AcaProject';
import ProblemIde from './Component/Detailpage/ProblemIde';
import Articlewriting from './Component/Detailpage/Articlewriteing';
import BasicBreadCrumb from './Component/BreadCrumb';
import Contact from './Component/Contact';
import Literaturewrt from './Component/Detailpage/Literaturewrt';
import Thesiswrit from './Component/Detailpage/Thesiswrit';
import Presentation from './Component/Detailpage/Presentation';
import Algorithamdev from './Component/Detailpage/Algorithamdev';
import ArticlePub from './Component/Detailpage/Articlepub';
import Softwareimp from './Component/Detailpage/Softwareimp';
function App() {
  return (
  <>
  <BrowserRouter>
    <ScrollTop/>
     <div className="">       
         <Header/> 
         {/* <BasicBreadCrumb/>  */}
              <Routes>
                   <Route path="/" element={<Home/>}/>
                   <Route path="aboutus" element={<AboutUs/>}/>
                   <Route path="service" element={<Service/>}/>
                   {/* <Route path="/contact" element={<Contact/>}/> */}
                    <Route path="service/academic" element={<Academic/>}/>
                    <Route path="service/fundingproposal" element={<Funding/>}/>
                    <Route path="service/patentingservice" element={<Patenting/>}/>
                    <Route path="service/academicproject" element={<AcaProject/>}/>
                    <Route path="service/academic/problem" element={<ProblemIde/>}/>
                    <Route path="service/academic/literaturewritting" element={<Literaturewrt/>}/>
                    <Route path="service/academic/thesiswriting" element={<Thesiswrit/>}/>
                    <Route path="service/academic/presentation" element={<Presentation/>}/>
                    <Route path="service/academic/articlewriting" element={<Articlewriting/>}/>
                    <Route path="service/academic/algorithmdevelopment" element={<Algorithamdev/>}/>
                    <Route path="service/academic/articlepublication" element={<ArticlePub/>}/>                      
                    <Route path="service/academic/softwareimplementation" element={<Softwareimp/>}/>
              </Routes>
                  

         
        <Footer/>
      </div>
      </BrowserRouter>
      </>
  );
}

export default App;
