import React, { useState } from 'react'
import Counter from './Counter'
import { motion, Variants } from "framer-motion";
import ScrollTrigger from 'react-scroll-trigger'


const container = {
    show: {
        transition: {
            staggerChildren: 0.2
        }
    }
};

const item = {
    hidden: { opacity: 0, y: 100 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: 'easeInOut',
            duration: 0.4,

        }
    }
}

export default function AboutUs() {
    const [counterOn, setCounterOn] = useState(false)
    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <section className='w-full flex flex-col h-full'>
                <div className='w-full h-[100px]  md:h-[250px] bg-gradient-to-r from-cyan-500 to-blue-500 text-white'>
                    <div className='md:w-[80%] md:mx-auto h-full  flex items-center justify-center'>
                        <h1 className='md:text-[4rem] font-space font-bold'>About us</h1>
                    </div>
                </div>

                <section className='flex w-full md:flex-row flex-col bg-white md:py-10 py-5 h-full px-5'>
                    <div className='md:mx-auto grid md:grid-cols-2 justify-center md:w-[80%] md:h-[600px] gap-y-5  bg-transparent md:gap-x-9'>
                        <div className='md:w-[65%] w-[70%] font-raleway  md:py-[70px] bg-transparent mb-2 md:mb-0'>
                            <motion.h2
                                initial={{ y: 200, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 1, delay: 0.1, ease: 'easeInOut' }}
                                className='md:text-[38px] text-[25px] font-Roboto'>With the Right Consultant, Great Things Can Happen</motion.h2>
                        </div>

                        <div className='md:w-[80%]   flex  flex-col justify-evenly md:text-[16px]  leading-7 text-justify font-Sans gap-y-5'>
                            <p className='leading-8 font-Roboto'>
                                In a world where research is the bedrock of progress, organizations like MREC play a pivotal role in ensuring
                                that this research is as efficient, effective, and innovative as possible. By putting the needs of researchers first and striving
                                to "Make Research Easy and Creative," MREC is undoubtedly paving the way for a brighter academic future.
                            </p>
                            <h1 className='font-Roboto text-xl font-bold'>Objective</h1>
                            <p className='leading-8 text-justify font-Roboto'>                               
                                At its core, MREC aspires to transform the perception and execution of academic research. 
                                Rather than viewing it as a tedious or convoluted process, 
                                MREC aims to infuse creativity and simplicity into the endeavor,
                                 allowing researchers to not just work smartly, but also innovate freely.
                            </p>
                        </div>
                    </div>

                </section >

                <section className='flex w-full md:flex-row flex-col bg-white md:py-3 py-5 h-full px-5'>
                     <div className='md:mx-auto grid md:grid-cols-2 justify-center md:w-[80%]  gap-y-5  bg-transparent md:gap-x-9'>
                             <div>
                                  <img src="https://img.freepik.com/free-vector/business-vision-concept-illustration_114360-7130.jpg" alt="vision"  className='w-[360px]'/>
                             </div>
                             <div>
                                  <h1 className='text-xl font-Roboto font-bold'>Our Foundation and Vision</h1>
                                  <p className='mt-2 text-sm  leading-8 text-justify font-Roboto'>
                                  MREC was born out of the realization that the world of academic research, while crucial, is often fraught with complexities,
                                   intricacies, and barriers. These challenges sometimes detract from the core goal of the research: to uncover truths, push boundaries, and drive progress. 
                                   The founders, themselves seasoned research enthusiasts, envisioned a future where researchers could bypass these hurdles and delve straight into the heart of their inquiries.

                                  </p>
                             </div>
                     </div>          
                </section>

                {/* <section className='w-full'>
                <div className='md:w-[80%]  md:mx-auto bg-white py-5 px-3'>
                    <h1 className='font-space text-[36px] font-bold'>Milestones</h1>
                    <div className='grid md:grid-cols-4 md:gap-x-4  md:gap-y-10 gap-y-4  items-center  p-10 font-space'>
                        <div className=' font-bold flex justify-start text-[20px]'>                            
                            {counterOn && <Counter number={7} title="Experience "/>}
                        </div>
                        <div className=' flex justify-start text-[22px] font-bold'>                        
                            <Counter  number={90} title="Delivery" /> 
                            <span className='ml-1'>% on time</span>
                        </div>
                        <div className=' flex justify-start text-[20px] font-bold items-center'>                            
                          {counterOn && <Counter title="Happy Clients" number={150}  />}
                             <span className='ml-1'>+</span>
                        </div>
                    <div className='flex justify-start text-[20px] font-bold items-center'>
                             
                            <Counter title="PhD" number={15}  />
                            <span  className='ml-1' >+</span>
                        </div>
                        <div className='flex justify-start font-bold text-[20px] items-center'>                            
                            <Counter title="Mphil" number={40}  />
                            <span  className='ml-1'>+</span>
                        </div>
                        <div className='flex justify-start text-[20px] font-bold items-center'>
                            
                            <Counter title="Articles Supported" number={80}  />
                            <span className='ml-1'>+</span>
                        </div>
                        <div className='flex justify-start text-[20px] font-bold items-center'>
                            
                            <Counter title="UG & PG Projects" number={10}  />
                            <span className='ml-1'>+</span>
                        </div>
                        <div className=' text-[20px] font-bold flex justify-start items-center'>
                            
                            <Counter title="Funding Proposals" number={10} />
                            <span className='ml-1'>+</span>
                        </div>
                    </div>
                </div>
            </section> */}


                <section className=' flex flex-col w-full bg-white py-10 md:px-5 '>
                    <div className='md:w-[80%] w-full md:mx-auto  h-full bg-white px-2'>
                        <div className='md:w-[50%] bg-white'>
                            <h1 className='font-Roboto md:text-[38px] text-[26px] mb-3 font-bold'>Why we are?</h1>
                            <p className='text-justify text-[16px] font-Roboto'>Seven assets of MREC to be the excellence at service</p>
                        </div>

                        <div className='grid md:grid-cols-[1fr_minmax(300px,_1fr)_1fr] mt-14 md:gap-x-4 gap-y-4 md:py-10  cursor-pointer  font-Sans'
                        /*  variants={container} initial="hidden" whileInView="show" viewport={{once:false}}       exit="exit"      */
                        >

                            <motion.div variants={item} className='md:px-[52px] px-3 md:py-[50px] py-4 h-full border-solid border-2 border-[#ddd] md:hover:bg-[#2d3092] md:hover:text-white '>
                                <h3 className='mb-3 font-bold'>Creativity</h3>
                                <p className=' text-[16px]  leading-9'>
                                    To provide client-centered services, we encourage innovation,reflecting thinking,flexibility,and adaptation.
                                </p>
                            </motion.div>
                            <motion.div variants={item}
                                className=' md:px-[52px] px-3 md:py-[50px] py-4 border-solid border-2 border-[#ddd] md:hover:bg-[#2d3092] md:hover:text-white  '>
                                <h3 className='mb-3 font-bold'>Integrity</h3>
                                <p className=' text-[16px] leading-9'>
                                    We make decisions openly and honestly to improve the quality of research.
                                    We encourage ethical and compliant research.
                                </p>
                            </motion.div>
                            <motion.div variants={item} className='md:px-[52px] px-3 md:py-[50px] py-4 flex flex-col border-solid border-2 border-[#ddd]  md:hover:bg-[#2d3092] md:hover:text-white'>
                                <h3 className='mb-3 font-bold'>Diversity</h3>
                                <p className='text-left text-[16px] leading-9'>
                                    As an organisation or individual that benefits
                                    from diverse viewpoints and experiences, we welcome personnel diversity.
                                </p>
                            </motion.div>


                        </div>

                        <div variants={container} initial="hidden" whileInView="show" /* viewport={{once:false}}  */
                            className='grid md:grid-cols-[1fr_minmax(300px,_1fr)_1fr] md:gap-x-4 gap-y-4 mt-5 font-Roboto'>

                            <motion.div variants={item} className=' md:px-[52px] px-3 md:py-[50px] py-4 flex flex-col  border-solid border-2 border-[#ddd] md:hover:bg-[#2d3092] md:hover:text-white hover:cursor-pointer '>
                                <h3 className='mb-3 font-bold'>Quality</h3>
                                <p className='text-justify text-[16px] leading-9 font-Roboto'>
                                    We are committed to establishing a
                                    variety of tools and ways to provide
                                    the individual with high-quality services that position
                                    them for success.
                                </p>
                            </motion.div>
                            <motion.div variants={item} className=' md:px-[52px]  px-3 md:py-[53px] py-4 flex flex-col border-solid border-2 border-[#ddd] md:hover:bg-[#2d3092] md:hover:text-white hover:cursor-pointer '>
                                <h3 className='mb-3 font-bold'>Timeliness</h3>
                                <p className='text-justify text-[16px] leading-9'>
                                    We ensure to deliver our projects on time. Doing
                                    research is not as same as delivering text documents.
                                    We team spends more time to understand the problem.
                                </p>
                            </motion.div>
                            <motion.div variants={item} className=' md:px-[52px] px-3 md:py-[50px] py-4 flex flex-col border-solid border-2 border-[#ddd] md:hover:bg-[#2d3092] md:hover:text-white hover:cursor-pointer'>
                                <h3 className='mb-3 font-bold '>Privacy</h3>
                                <p className='text-justify text-[16px] leading-9'>
                                    We always respect our client’s privacy.
                                    Personal information about researchers' and research data will be kept strictly confidential.
                                    Our organisation will never share any of our clients'
                                    personal information with anybody else.
                                </p>
                            </motion.div>

                        </div>

                        <div variants={item} initial="hidden" whileInView="show" viewport={{ once: false }}
                            className='md:px-[52px] mt-5 px-3 md:py-[50px] py-4 flex flex-col  border-solid border-2 border-[#ddd] md:hover:bg-[#2d3092] md:hover:text-white font-Sans
                            hover:cursor-pointer'>
                            <h3 className='mb-3 font-bold '>Support</h3>
                            <p className='text-justify text-[16px] leading-9'>
                                Being attentive, proactive, and imaginative will help researchers at the university/college in the future.
                                Balanced service and compliance to enhance the quality of research.
                                Our effort will take up a significant part of the researchers' career.
                                We always go above and beyond to exceed your expectations. Our assistance will continue even the work is completed.
                                The majority of the research will encounter difficulties at some point during the research process.We will provide assistance till our client is completely satisfied.

                            </p>
                        </div>
                    </div>
                </section>

            </section>

        </ScrollTrigger>
    )
}
