import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'
export default function Literaturewrt() {
    return (
        <div className='flex flex-col w-full h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Literature Writing</h1>
                </div>
            </div>

            <section className='w-full py-1'>
            <div className='md:w-[80%] w-full md:mx-auto md:py-[1rem] text-justify bg-white'>
                    <h1 className='font-Roboto md:text-[24px] px-[1rem]  md:px-0'>Literature Writing: The Foundation of Academic Inquiry</h1>
                    <p className='leading-9 font-popins   md:px-3 px-[1rem]  md:py-5 text-justify text-[16px]'>
                    Before embarking on the research voyage, it's essential to understand the vast ocean of knowledge 
                    that exists. Literature writing is not merely a review but a synthesis of what is known, what remains ambiguous,
                     and where your research can bridge the gap. It paints the backdrop against which your study unfolds, ensuring 
                     that your contributions are grounded, relevant, and innovative.

                    </p>

                    <div className='py-[2rem] text-justify flex md:flex-row flex-col'>
                        <div className='md:w-[50%]'>
                            <h1 className='px-2 md:px-0 font-Roboto md:text-2xl'>Importance of Literature Writing</h1>
                        </div>
                        <div className='md:w-[60%] w-full flex flex-col gap-y-4 text-[16px] mt-2 md:mt-0  divide-y-2'>
                            <div className='px-3 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]  'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Contextual Understanding</span>
                                    <p>
                                    Literature provides the context for your research, placing your work within the grand tapestry of academic discourse
                                    </p>
                                </div>
                            </div >

                            <div className='px-3 mt-2 flex  md:flex-row flex-col items-start md:items-center md:gap-x-7  gap-y-4   p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]  'size={20}  />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Identifying Gaps</span>
                                    <p>
                                    By understanding what's been explored, you can find niches or uncharted territories that require further study
                                    </p>
                                </div>

                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7  gap-y-4   p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20}  />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Avoiding Redundancy</span>
                                    <p>
                                    With the vast amount of research being conducted globally, literature writing ensures you're not reinventing the wheel.
                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7  gap-y-4   p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Building Credibility</span>
                                    <p>
                                    A well-researched literature section shows rigor in your approach, earning trust from peers and readers alike.
                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start md:items-center md:gap-x-7  gap-y-4  p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]'size={20}  />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Guiding Methodology</span>
                                    <p>
                                    By analyzing previous methodologies, you can refine and perfect your own, ensuring higher accuracy and relevance
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='md:py-[2rem]  px-[1rem] text-justify'>
                        <h1 className='md:text-[24px] text-base font-Roboto'>MREC's Support for Literature Writing</h1>
                        <p className='text-sm mt-2'>
                        Navigating the sea of academic literature can be overwhelming. At MREC, we're committed to making this
                         journey smoother for you.
                        </p>
                        <div className='grid md:grid-cols-2 grid-cols-1  gap-y-3 pt-10'>
                            <div className='flex flex-col gap-y-4 text-[16px] text-justify'>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96] cursor-pointer'>
                                    <span className='text-white text-2xl'>Literature Sourcing</span>
                                    <p className='text-white'>  With access to extensive academic databases, we help you identify and source relevant papers, articles, and publications.
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#7e6ee6] cursor-pointer'>
                                    <span className='text-2xl text-white'>Organized Synthesis</span>
                                    <p className='text-white'>
                                    Our team aids in collating and synthesizing literature, ensuring a coherent and structured review..
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col md:items-end gap-y-4 text-[16px] text-justify'>
                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#7e6ee6] cursor-pointer '>
                                    <span className='text-2xl text-white' >Gap Identification</span>
                                    <p className='text-white'>
                                    Our expertise lies in not just understanding what's there, but also in spotting what's missing, giving your research a cutting-edge focus.
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#7e6ee6] cursor-pointer'>
                                    <span className='text-2xl text-white'>Workshops & Consultations</span>
                                    <p className='text-white'>
                                    Stay updated with the latest techniques in literature review writing through our specialized workshops and one-on-one consultations.

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>                   

                   
                </div>
            </section>
        </div>
    )
}
