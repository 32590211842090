import React from 'react'


const domain=[
    {
        title:"Data Mining Projects"
    },
    {
        title:"Image Processing Projects"
    },
    {
        title:"Cloud Computing Projects"
    },
    {
        title:"Networking Projects"
    },
    {
        title:"Security Projects"
    },
    {
        title:"Wireless Network Projects"
    },
    {
        title:"Big Data  Projects"
    },
    {
        title:" Embedded Projects"
    },
    {
        title:" VLSI Projects"
    },
    {
        title:" Bio-Medical Projects"
    },
    {
        title:" IoT Projects"
    },
    {
        title:"  Signal Processing projects"
    },
    {
        title:"  Android projects"
    },
    {
        title:"  Web Application projects"
    },


]
export default function AcaProject() {
  return (
    <div className='h-full w-full flex flex-col bg-white ' >
    <div className='bg-[#2d3092] text-white md:h-[250px] '>
        <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
            <h1 className='md:text-[56px] font-bold font-space'>Academic Projects</h1>
        </div>
    </div>


      <section className='w-full flex flex-col'> 
        <div className='md:w-[80%] w-full md:mx-auto flex md:flex-row flex-col md:py-10 bg-white px-5 md:gap-x-4'>
                   <div className='md:w-[50%] w-full  md:px-0'>
                         <p className='leading-9 font-popins text-[16px] text-justify'>
                         There is an urgent need for workers in the Indian economy who are either well-versed 
                         in general information technology (IT) or who have developed expertise in a particular area of IT,
                          such as finance and accounting, infrastructure management, web content development,
                           enterprise resource planning, or business intelligence.<br></br>
                             Academic projects are student research projects that are carried out as part of a student's course,
                              degree, or study. MREC supports major and mini projects. The student will get exposure on the 
                              cutting-edge technologies. The outcome of the project report will help them to grab better 
                              opportunities in the industries.<br></br>
                              Make Research Easy and Creative, or MREC for short, offers invaluable assistance to students and programmers in the process of configuring and installing projects as well as writing technical documentation. Research is the foundational step in the development of any idea.
                         </p>
                   </div>
                   <div className='bg-slate-300 md:w-[50%] w-full px-3'>
                           {/* <h1>Image</h1> */}
                   </div>

        </div>            
      </section>

      <section className='w-full flex flex-col md:py-10 py-5 px-5 '>
           <div className='md:mx-auto md:w-[80%] w-full grid md:grid-cols-2 md:bg-[#2d3092]  gap-y-3 py-0   '>
                 <div className='flex md:flex-grow bg-white items-center justify-start '>
                   <h1 className='md:text-[35px] text-[20px] font-space font-semibold'>Real-Time Projects</h1> 
                   </div>
                   <div className='bg-white '>
                      <p className='leading-9 text-[18px] font-popins text-justify'>
                      We ascertain the project specifications suggested by IEEE and other reputed journal papers. 
                      Then, we determine the limits of the current systems. We do research on the proposed 
                      system in more detail if it is necessary for project development.

                      </p>
                   </div>
           </div>
      </section>


      <section className='w-full flex flex-col md:py-10 '>
                  <div className='md:w-[80%]  w-full md:mx-auto md:py-10 px-5'>
                       <h1 className='font-space md:text-[30px] text-[20px] mb-2 font-bold'>Project Domain</h1>
                       <p className='text-[18px] font-Roboto '>
                        Projects domains include but are not limited to ,the following
                       </p> 
                             <div className='grid md:grid-cols-4  mt-3 md:px-3 py-5 md:gap-9 gap-y-5'>
                                    {
                                        domain.map((d ,index)=>(
                                               <h3 key={index} className='font-popins md:font-normal  tracking-wide text-[16px] md:h-[60px] h-[50px] flex items-center cursor-pointer justify-center bg-neutral-100 rounded-2xl md:hover:bg-[#2d3092] md:hover:text-white md:hover:font-bold'>{d.title}</h3>
                                        ))
                                    }
                             </div>

                  </div>
      </section>


      <section className='w-full flex flex-col py-5 '>
           <div className='md:mx-auto md:w-[80%] w-full grid md:grid-cols-2   gap-y-3 py-0 px-5 '>
                 <div className='flex flex-grow bg-white items-start justify-start '>
                   <h1 className='md:text-[35px] text-[20px] font-space font-semibold'>Code Developement</h1> 
                   </div>
                   <div className='bg-white leading-9 text-[18px] font-popins text-justify'>
                      <p className=''>
                      Code development is an essential component of any digital endeavour. The approach from the study article 
                      is used to create an application. The development phase entails writing code for a complete 
                      application. Code generation is a simple process. To code, compilers and interpreters such as
                       <bold>C, C++, PHP, Java, Python, ReactJS, R, and Go</bold> lang are used.<br></br>  </p>
                      <p> The process of adopting and integrating a software application into a company workflow is known as "software implementation" (as a part of a project documentation).</p>
                        <p>
                        Following coding, testing commences. There are various methods for detecting code faults. There are numerous testing instruments available for this purpose. The project 
                        will be available for distribution to students following testing. Taking into account the student's feedback.
                        </p>

                    
                   </div>
           </div>
      </section>

      <section className='w-full flex flex-col md:py-10 '>
           <div className='md:mx-auto md:w-[80%] w-full grid md:grid-cols-2  gap-y-3 py-0 px-5  '>
                 <div className='flex flex-grow bg-white items-start justify-start '>
                   <h1 className='md:text-[35px] text-[20px] font-space font-semibold'>Article Publication</h1> 
                   </div>
                   <div className='bg-white leading-9 text-[18px] font-popins text-justify'>
                       <p>
                       The students may find that writing a Research Article on the subject of the project is an important stage in the process. 
                       It is mandatory that the student and their advisor publish the research article on well-known indexed journals.

                       </p>
                       <p>
                       We assisted in the publication of research papers in more than two hundred and fifty different scientific periodicals and journals.
                        We publish articles on a consistent basis in IEEE as well as other reputable scientific journals including Elsevier, Springer, Science Direct, ACM, Emerald, Science Direct, IEEE Xplore, and others.

                       </p>

                    
                   </div>
           </div>
      </section>

      <section className='w-full flex flex-col py-10 '>
           <div className='md:mx-auto md:w-[80%] w-full grid md:grid-cols-2   gap-y-3 py-0 px-5 md:px-0  '>
                 <div className='flex flex-grow bg-white items-start justify-start '>
                   <h1 className='md:text-[35px]  text-[20px] font-space font-semibold'>Documentation</h1> 
                   </div>
                   <div className='bg-white leading-9 text-[18px] font-popins text-justify'>
                       <p>
                       MREC encompasses more than only documentation and sale projects. In addition, the learner is better able to comprehend
                        and learn how to configure project components, install applications, and build setup files as a result of this. 
                        Students will receive assistance from MREC in learning how to write technical papers such as project abstracts, project synopses, project reports, test cases, test plans, and programme specifications.

                       </p>
                       <p>
                       The act of plagiarising another person's work is a problem that plagues the field of research and development in the modern era.
                        The act of taking and publishing the ideas and research of other authors while passing it off as one's own work is known as plagiarism.
                       </p>
                           <p>
                           MREC has an account set up on a widely used plagiarism checking programme so that it may identify instances of duplicated text in project documents and research articles. This method helps to stop other people from copying the content by preventing them from duplicating it. Before distribution,
                            the documents are scanned thoroughly, and their size is reduced to less than 20%.
                           </p>
                    
                   </div>
           </div>
      </section>



    </div>
  )
}
