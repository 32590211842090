import React from 'react'
import { FaHands } from "react-icons/fa"
import { HiLink, HiLockClosed } from "react-icons/hi"
import { motion, Variants } from "framer-motion";
import LandingPage from './LandingPage';
// import image2 from './image2.jpg'
// import image3 from './image3.png'
import image4 from './image4.svg'
import {fadeIn} from '../Component/animation'
import image1 from '../Component/hero.webp'


const container = {
     show:{
         transition:{
             staggerChildren:0.1
         }
     }
 };

const textAnimate = {
     offscreen:{x:-100, opacity:0},
     onscreen:{x:0,
     opacity:1,
     transition: {type:"spring",
     bounce:0.2,
     duration:2}
  }
 
 }

 const textAnimate1 = {
     offscreen:{x:-100, opacity:0},
     onscreen:{x:0,
     opacity:1,
     transition: {type:"spring",
     bounce:0.4,
     duration:2}
  }
 
 }

 

export default function Hero() {
     return (
          <>
               <div className='h-full w-full md:h-[600px] py-10 md:w-full flex flex-col  bg-white'>
                    <motion.div variants={container}   initial={"offscreen"} animate={"onscreen"}
                    className='md:mx-auto md:items-center w-full md:w-[1240px] h-full bg-white flex md:flex-row md:px-0  flex-col-reverse'>
                         <motion.div  
                         className='flex flex-col justify-center items-start md:w-[50%] mt-4 md:mx-0 mx-2 md:pl-0 '>
                              <motion.h1 className='md:text-5xl text-[29px] pl-3 font-raleway font-bold ' variants={textAnimate}>Make Research</motion.h1>
                              <motion.h1 className='md:text-3xl pb-5 text-[29px] pl-3 font-raleway font-bold' variants={textAnimate1}>Easy and Creative</motion.h1>
                              <p className='text-[16px] pl-3 font-Roboto'>We love to solve problems. Leave your <br></br> problems to us</p>
                              <button className='mt-7 bg-black text-white px-4 py-2 rounded-md ml-2 md:w-[300px] w-auto font-space font-bold tracking-wider'>Get started</button>

                              <div className='md:flex px-3 gap-5 jutify-center 
                              items-center md:text-[16px] text-[16px]  mt-10 leading-5  font-Roboto font-semibold'>

                                   <div className='w-full md:w-auto flex items-center gap-5 md:gap-x-2 md:mb-0 mb-6   '>
                                        <HiLockClosed className='text-[20px]' />
                                        <span>  Privacy & Confidentiality</span>
                                   </div>
                                   <div className='w-full md:w-auto flex items-center gap-5 md:gap-x-2 md:mb-0 mb-6 '>
                                        <FaHands className='text-[20px]' />
                                        <span>Timeliness & Support</span>
                                   </div>
                                   <div className='w-full md:w-auto flex  items-center gap-5 md:gap-x-2 md:mb-0 mb-6 '>
                                        <HiLink className='text-[20px]' />
                                        <p>Better Knowledge</p>
                                   </div>
                              </div>
                         </motion.div>
                         <div className='w-full md:w-[50%] flex  justify-center items-center px-3 object-cover'>
                              <img src={image1} alt="heroimage" />
                         </div>

                    </motion.div>

               </div>

                

               

               <section             
               className='w-full bg-white md:py-10 py-5 px-2 md:px-0' >
                    <div className='md:mx-auto  w-full md:w-[80%] grid md:grid-cols-2 bg-white md:py-[3rem] px-2 md:gap-x-2 gap-y-4' >
                         <div
                        
                            className='bg-white'>
                              <img src={image4} alt="image4" className='md:w-[360px] object-cover' />
                         </div>
                         <div
                        
                          className='bg-white w-full flex flex-col justify-center md:px-5'>
                              <h1 className='font-Roboto  font-bold text-[23px] md:text-[36px] tracking-wide md:leading-9'>Built for Researchers,<br></br> By Researchers</h1>
                              <p className=' leading-8 text-[16px] mt-6 font-Sans text-justify'>
                              MREC stands for "Make Research Easy and Creative" and is an academic research consulting organisation that was created 
                              for academics by research enthusiasts. MREC's mission is to "Make Research Easy and Creative." The goal of the MREC is to 
                              find a simple solution to the complicated issue that is having a significant influence on the community.
                              </p>
                         </div>

                    </div>
               </section>

              <section className='w-full bg-white md:py-10 py-5 px-2 md:px-0'>
                  <LandingPage/>
              </section>

          </>
     )
}

