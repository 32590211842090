import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'
export default function Algorithamdev() {
  return (
    <div className='flex flex-col w-full h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Algorithm Development</h1>
                </div>
            </div>

            <section className='w-full'>
                <div className='md:w-[80%] w-full md:mx-auto  py-[1rem] md:py-[1rem] text-justify bg-white'>
                    <h1 className='font-Roboto px-[1rem]  md:text-[24px] md:px-0'>Algorithm Development: Crafting Logic for Tomorrow's Solutions</h1>
                    <p className='leading-9 font-popins md:px-3 px-[1rem]  md:py-5 text-justify text-[16px]'>
                    In an age where data drives decisions and technology shapes destinies, algorithms stand at the very core 
                    of innovation. They're the hidden logic behind our favorite apps, the silent guardians of data integrity, 
                    and the unsung heroes making sense of vast information landscapes. Algorithm development is an art and 
                    science of crafting these logical sequences to solve specific problems.


                    </p>

                    <div className='py-[2rem] text-justify flex md:flex-row flex-col'>
                        <div className='md:w-[50%]'>
                            <h1 className='font-Roboto md:text-2xl px-2 md:px-0'>Why Algorithm Development is Crucial</h1>
                        </div>
                        <div className='md:w-[60%] flex flex-col gap-y-4 text-[16px] mt-2 md:mt-0  divide-y-2'>
                            <div className='px-3 flex md:flex-row flex-col items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className=' bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]  'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Efficiency</span>
                                    <p>
                                    Properly developed algorithms ensure tasks are completed in the shortest time and with the least resources
                                    </p>
                                </div>
                            </div >

                            <div className='px-3 mt-2 flex   md:flex-row flex-col items-start  md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]  'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Scalability</span>
                                    <p>
                                    As data grows, algorithms that can scale ensure systems remain responsive and effective.
                                    </p>

                                </div>

                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start  md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken className='text-[#652ba7]  'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Precision</span>
                                    <p>
                                    Well-crafted algorithms provide accurate and reliable results, crucial for critical applications in medicine, finance, and beyond

                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:flex-row flex-col items-start  md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md '>
                                    <GiThreePointedShuriken className='text-[#652ba7]  'size={20}/>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Innovation</span>
                                    <p>
                                    From AI to predictive analytics, groundbreaking advancements are often built upon novel algorithms.
                                    </p>
                                </div>
                            </div>

                           
                        </div>
                    </div>

                    <div className='py-[2rem]'>
                           <h1>Steps in Algorithm Development</h1>
                           <p>
                                  ***timeline***
                           </p>
                    </div>

                    <div className='py-[2rem] text-justify px-[1rem]  md:px-0 '>
                        <h1 className='font-Roboto md:text-2xl'>MREC's Support in Algorithm Development</h1>
                        
                        <p className='text-sm mt-2'>
                        At MREC, we understand the intricacies and nuances of algorithmic logic. 
                        Our team is dedicated to guiding and assisting researchers and innovators in this domain.

                        </p>
                        <div className='grid grid-cols-1  md:grid-cols-2 gap-y-3 gap-x-3 pt-10'>
                            <div className='flex flex-col gap-y-4 text-[16px] text-justify'>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Expert Consultation</span>
                                    <p className='text-white'> Engage with professionals seasoned in diverse algorithmic challenges
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Workshops</span>
                                    <p className='text-white'>
                                    Participate in sessions focused on the latest trends, tools, and best practices in algorithm development
                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col md:items-end gap-y-4 text-[16px] text-justify'>
                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Collaboration Opportunities</span>
                                    <p className='text-white'>
                                    Network with like-minded individuals, fostering potential research or innovation partnerships
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Resources & Tools</span>
                                    <p className='text-white'>
                                    Gain access to a repository of resources, sample algorithms, and tools to aid your development journey

                                    </p>
                                </div>

                                
                            </div>
                          
                        </div>
                        <p className='mt-5 text-[16px] md:w-[70%] md:mx-auto'>
                                Algorithms are the backbone of the digital age, with MREC's unwavering support, you're empowered to contribute to this evolving story.
                             </p>
                    </div>                   

                   
                </div>
            </section>
        </div>
  )
}
