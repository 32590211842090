import React from 'react'
import { GiThreePointedShuriken } from 'react-icons/gi'
export default function Presentation() {
  return (
    <div className='flex flex-col w-full h-full'>
            <div className='bg-[#2d3092] text-white md:h-[250px] '>
                <div className='md:w-[80%] w-full md:mx-auto   md:h-[250px] h-[100px] flex f items-center justify-center'>
                    <h1 className='md:text-[56px] font-bold font-space'>Presentation Support</h1>
                </div>
            </div>

            <section className='w-full'>
                <div className='md:w-[80%] w-full md:mx-auto py-[1rem] text-justify bg-white'>
                  <div className='px-3'>
                <h1 className='font-Roboto text-xl'>Presentation Support: Crafting Visual Narratives that Captivate and Convince</h1>
                    <p className='leading-9 font-popins   md:py-5 text-justify text-[16px]'>
                   
                    A presentation goes beyond mere slides; it's a visual journey, narrating a story, selling an idea, 
                    or sharing research findings. With mere minutes to make an impact, every slide, every graphic, and
                     every word counts. At MREC, we elevate your narratives through impeccable presentation support, 
                     ensuring your audience remains engaged and informed.

                    </p>
                    </div>     
                    <div className='py-[2rem] px-2 md:px-0 text-justify flex md:flex-row flex-col'>
                        <div className='md:w-[50%]'>
                            <h1 className='font-Robot text-[24px] '>Essentials of a Powerful Presentation</h1>
                        </div>
                        <div className='md:w-[60%] flex flex-col md:flex-row gap-y-4 text-[16px] divide-y-2'>
                            <div className='px-3 flex flex-col md:flex-row  items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Purpose-Driven Design</span>
                                    <p>
                                    Every slide tailored to emphasize key points, ensuring clarity and avoiding information overload
                                    </p>
                                </div>
                            </div >

                            <div className='px-3 mt-2 flex flex-col md:flex-row  items-start md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]' size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Engaging Graphics</span>
                                    <p>
                                    Utilizing visuals, infographics, and charts that resonate with the content and captivate the audience.

                                    </p>
                                </div>

                            </div>

                            <div className=' px-3 mt-2  flex items-start md:flex-row flex-col  md:items-center md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Cohesive Flow</span>
                                    <p>
                                    Ensuring a logical progression of ideas, seamlessly guiding the audience from introduction to conclusion.
                                    </p>
                                </div>
                            </div>

                            <div className=' px-3 mt-2  flex md:items-center flex-col md:flex-row  items-start  md:gap-x-7 gap-y-4 p-6 overflow-hidden rounded-[0px]'>
                                <div className='bg-[#dfd8eb]/[50%] rounded-[10%] p-2 flex items-center shadow-md'>
                                    <GiThreePointedShuriken  className='text-[#652ba7]'size={20} />
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <span className='text-xl'>Audience Engagement</span>
                                    <p>
                                    Designing interactive elements and cues that promote audience participation and maintain interest.
                                    </p>
                                </div>
                            </div>

                           
                        </div>
                    </div>

                    <div className='py-[2rem] text-justify px-2 '>
                        <h1 className='font-Roboto text-xl'>MREC's Approach to Presentation Excellence</h1>
                       
                        <div className='grid  md:grid-cols-2 grid-cols-1 gap-y-3 pt-10'>
                            <div className='flex flex-col gap-y-4 text-[16px] text-justify'>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Customized Templates</span>
                                    <p className='text-white'>  Beyond generic designs, our team crafts slide templates that reflect the essence of your topic and resonate with your brand or institution
                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96] flex-1'>
                                    <span className='text-white text-2xl'>Content Refinement</span>
                                    <p className='text-white'>
                                    Streamlining content to be concise yet impactful, ensuring maximum retention.

                                    </p>
                                </div>
                            </div>

                            <div className='flex flex-col items-end gap-y-4 text-[16px] text-justify'>
                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96] '>
                                    <span className='text-white text-2xl'>Visual Enhancement</span>
                                    <p className='text-white'>
                                    Incorporating infographics, animations, and relevant multimedia to elevate the viewing experience.

                                    </p>
                                </div>

                                <div className='md:w-[80%] flex flex-col gap-y-3 p-9 overflow-hidden box-border rounded-[10px] bg-[#fa6f96]'>
                                    <span className='text-white text-2xl'>Practice Sessions</span>
                                    <p className='text-white'>
                                    Offering platforms and guidance for mock presentations, ensuring you're prepared and confident

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>                   

                    <div className='w-full md:py-[3rem] flex md:flex-row flex-col px-2 '>
                               <div className='md:w-[50%]'>
                               <h1 className='font-Roboto text-xl'>A Tool for Every Task</h1>
                               </div>
                          
                          <div className='md:w-[50%]'>
                             <p className='text-[14px] leading-8 text-justify'>
                           In an age of technological marvels, presentations have evolved. We harness the power of leading presentation tools and software,
                            ensuring your content is presented in the best possible light, whether it's a boardroom, a webinar, or an international conference.
                                 Presentations can make or break an idea, a project, or even a career. With MREC's unwavering support, your presentations won't just communicate; 
                                 they'll captivate. Let us transform your content into a visual masterpiece that stands out and speaks volumes.

 
                           </p>
                           </div>
                    </div>
                </div>
            </section>
        </div>
  )
}
