import React from 'react'
import { Link } from 'react-router-dom'
export default function DropDown({submenus ,dropdown ,setdropdown}) {
     const closeDropDown=()=>{
          setdropdown(!false)
     }
  return (
    <div>
        <ul className={dropdown ?'absolute z-[9999] bg-white py-2 w-[220px] top-[150%] rounded-md':'hidden'}>
             {
                submenus.map((sub ,indes)=>(
                       <li className='text-[15px] cursor-pointer py-2 px-3 font-raleway hover:text-blue-500' >
                        <Link to={sub.url} onClick={closeDropDown}>{sub.title}</Link>
                       </li>  
                ))
             } 
        </ul>
    </div>
  )
}
